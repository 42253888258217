import "./css/reset.css"
import "@splidejs/splide/css"

// General
import "./css/colors.css"
import "./css/settings.css"
import "./css/fonts.css"
import "./css/typography.css"
import "./css/icons.css"

import "./css/layout.css"
import "./css/default.css"
import "./css/flow.css"
import "./css/buttons.css"
import "./css/forms.css"
import "./css/cmp-embeds.css"
import "./css/embeds.css"

import "./css/header.css"
import "./css/footer.css"
import "./css/plugins.css"
import "./css/breadcrumbs.css"
import "./css/teaser.css"
import "./css/article-list.css"
import "./css/article-detail.css"
import "./css/related.css"
import "./css/profile.css"
import "./css/search.css"
import "./css/filter.css"
import "./css/signup.css"
import "./css/insider.css"
import "./css/region-map.css"
import "./css/thematic-worlds.css"

import "./css/admin.css"
import { onReady, qs, qsa } from "./js/utils"

import { initEmbeds, waitFor } from "./js/cmp-embeds"
// import { initRecaptchaAutorefresh } from "./js/captcha"
import { initCommentForm, initComments } from "./js/comments"
import { initDetails } from "./js/details"
import { initFilter } from "./js/filter.js"
import { initForms } from "./js/forms.js"
import { initGalleries } from "./js/gallery"
import { renderSearch } from "./js/search.js"
// import { initPodcast } from "./js/podcast"
import { initSlider } from "./js/slider"
import { initSomeSlider } from "./js/some_slider"
import { initTestimonialSlider } from "./js/testimonial_slider"

import { initRegionMap } from "./js/region-map.js"
import { initSectionScroll } from "./js/signup/sectionScroll"
import { PaymentForm, ReferrerForm, Signup } from "./js/signup/signup"
import { initToggleElements } from "./js/signup/toggleElements.js"
import { initVideoPlayers } from "./js/signup/videoPlayer"

window.htmx = require("htmx.org")

onReady(() => {
  initEmbeds()
  waitFor(
    () =>
      Object.prototype.hasOwnProperty.call(window, "UC_UI") &&
      window.UC_UI.isInitialized(),
  ).then(() => {
    const cmpLinks = qsa(".cmp-privacysettings-link")
    for (const el of cmpLinks) {
      el.addEventListener("click", () => window.UC_UI.showSecondLayer())
    }
  })
})

// onReady(initRecaptchaAutorefresh)
onReady(initCommentForm)
onReady(initComments)
onReady(initSlider)
onReady(initTestimonialSlider)
onReady(initSomeSlider)
onReady(initGalleries)
onReady(initDetails)
onReady(initFilter)
onReady(initForms)
onReady(initRegionMap)

const adjustTextSizeForAll = () => {
  const containers = qsa(["[data-adjust-container]"])

  for (const container of containers) {
    const text = qs("[data-adjust-text]", container)

    if (!text) continue

    const maxHeight = container.offsetHeight
    const maxWidth = container.offsetWidth
    const minFontSize = 20
    let textHeight = text.offsetHeight
    let textWidth = text.offsetWidth
    let fontSize = 50

    text.style.fontSize = `${fontSize}px` // Setzt die Start-Schriftgröße zurück

    // Anpassung der Schriftgröße, wenn der Text zu groß für den Container ist
    while (
      (textHeight > maxHeight || textWidth > maxWidth) &&
      fontSize > minFontSize
    ) {
      fontSize-- // Schriftgröße schrittweise verringern
      text.style.fontSize = `${fontSize}px`
      textHeight = text.offsetHeight
      textWidth = text.offsetWidth
    }
  }
}

onReady(() => {
  window.addEventListener("resize", adjustTextSizeForAll)
  adjustTextSizeForAll()
})

const slideUp = (element, duration = 500) => {
  element.style.transitionProperty = "height, margin, padding"
  element.style.transitionDuration = `${duration}ms`
  element.style.boxSizing = "border-box"
  element.style.height = `${element.offsetHeight}px`
  element.offsetHeight // erzwingt das Neuzeichnen
  element.style.overflow = "hidden"
  element.style.height = 0
  element.style.paddingTop = 0
  element.style.paddingBottom = 0
  element.style.marginTop = 0
  element.style.marginBottom = 0
  window.setTimeout(() => {
    element.style.display = "none"
  }, duration)
}

onReady(() => {
  const handleDOMChange = () => {
    const test = qs(".messages-bar")
    if (test) {
      setTimeout(() => {
        slideUp(test, 150)
      }, 8000)
    }
  }
  const observer = new MutationObserver(handleDOMChange)
  observer.observe(document.body, { childList: true, subtree: true })
})

onReady(() => {
  window.addEventListener("cmp-embed-unwrapped", () => {
    const cseElements = qsa("[data-cse-cx]")
    for (const el of cseElements) {
      renderSearch(el)
    }
    initVideoPlayers()
    // initPodcast()
  })
})

// SIGNUP/MEMBER

onReady(() => {
  // Signup
  const signupForms = qsa("[data-signup-form]")
  for (const el of signupForms) {
    new Signup(el)
  }

  // Payment Form
  const paymentForms = qsa("[data-payment-form]")
  for (const el of paymentForms) {
    new PaymentForm(el)
  }

  // Referrer Form
  const referrerForms = qsa("[data-referrer-form]")
  for (const el of referrerForms) {
    new ReferrerForm(el)
  }
})

onReady(initVideoPlayers)
onReady(initSectionScroll)
onReady(initToggleElements)
